'use strict';

const elem = {

    notice:           document.getElementById('notice'),
    options:          document.getElementById('options'),
    menuButton:       document.getElementById('menu-button'),

    scaleSlider:      document.getElementById('scale-slider'),
    scaleMin:         document.getElementById('scale-min'),
    scaleMax:         document.getElementById('scale-max'),

    ruler:            document.getElementById('ruler'),
    rulerLabel:       document.getElementById('ruler-label'),

    timelines:        document.getElementById('timelines'),
    shadow:           document.getElementById('shadow'),

    spaceEvents:      document.getElementById('space-events'),
    lifeEvents:       document.getElementById('life-events'),
    humanEvents:      document.getElementById('human-events'),
    
    events:           document.getElementsByClassName('event'),

    darkAges:         document.getElementById('dark-ages'),
    reionization:     document.getElementById('reionization'),
    stellar:          document.getElementById('stellar'),

    periodsRange:     document.querySelectorAll('.periods-range'),

    hadean:           document.getElementById('hadean'),
    phanerozoic:      document.getElementById('phanerozoic'),

    eoarchaen:        document.getElementById('eoarchaen'),
    paleoarchaen:     document.getElementById('paleoarchaen'),
    mesoarchaen:      document.getElementById('mesoarchaen'),
    neoarchaen:       document.getElementById('neoarchaen'),

    paleoproterozoic: document.getElementById('paleoproterozoic'),
    mesoproterozoic:  document.getElementById('mesoproterozoic'),
    neoproterozoic:   document.getElementById('neoproterozoic'),

    paleozoic:        document.getElementById('paleozoic'),
    mesozoic:         document.getElementById('mesozoic'),
    cenozoic:         document.getElementById('cenozoic'),

    /*
    earth:            document.getElementById('earth'),
    dinosaurs:        document.getElementById('dinosaurs'),
    mammals:          document.getElementById('mammals'),
    primates:         document.getElementById('primates'),
    greatApes:        document.getElementById('great-apes'),
    homo:             document.getElementById('homo'),
    */

    toggleClass(elems, action, className) {
        //console.log(`elem.toggleClass(${elems}, ${action}, ${className})`);
        elems.forEach(el => {
            elem[el].classList[action](className);
        });
    },

    height(h, ...elems) {
        //console.log(`elem.height(${h}, ${elems})`);
        elems.forEach(el => {
            elem[el].classList.remove('height-5', 'height-10', 'height-15');
            elem[el].classList.add('height-' + h);
        });
    },

    hide(...elems) {
        //console.log(`elem.hide(${elems})`);
        elem.toggleClass(elems, 'add', 'hide');
    },

    unhide(...elems) {
        //console.log(`elem.unhide(${elems})`);
        elem.toggleClass(elems, 'remove', 'hide');
    },

    truncate(...elems) {
        //console.log(`elem.truncate(${elems})`);
        elem.toggleClass(elems, 'add', 'truncate');
    },

    untruncate(...elems) {
        //console.log(`elem.untruncate(${elems})`);
        elem.toggleClass(elems, 'remove', 'truncate');
    }

};
