/**
 * Adds move and resize functionality to an element.
 *
 * Apply to DOM element:     mar.create(el);
 *
 * @param {HTMLElement} el - Element to receive move and resize functionality.
 * @param {object} [opts]  - Options as detailed below.
 *
 * {number}  [minWidth = 65]: Minimum resizable width of el.
 * {boolean} [percent = false]: Use percent of screen instead of px for el width.
 * {string}  [moveClass = 'move-handle']: Class specifying child elements to use as move handles.
 * {string}  [resizeClass = 'resize-handle']: Child elements to use as resize handles.
 */

const mar = function () {
    'use strict';

    var create = function (el, opts = {}) {
        var minWidth    = opts.minWidth    || 65,
            percent     = opts.percent     || false,
            moveClass   = opts.moveClass   || 'move-handle',
            resizeClass = opts.resizeClass || 'resize-handle',

            redraw  = false,    // Redraw el during animation frame
            action  = null,     // Action being performed on el (move or resize)
            clicked = null,     // Initial measurements when el is clicked
            edge    = null,     // Edge of el the action is on (left or right)

            handles = el.querySelectorAll(`.${moveClass}, .${resizeClass}`),
            rect    = el.getBoundingClientRect(),

            thisEvent, left, top, right;
            

        function handleDown(ev) {
            console.info(`Event: ${ev.type} on ${ev.target.className}`);
            //console.log(`handleDown(${ev})`);
            ev.preventDefault();
            if (ev.target.classList.contains('resize-handle')) {
                action = 'resize';
                document.body.style.cursor = 'ew-resize';
            } else if (ev.target.classList.contains('move-handle')) {
                action = 'move';
                document.body.style.cursor = 'move';
            }
        }


        function convertWidthUnits(width) {
            //console.log(`convertWidthUnits(${width})`);
            percent && (width = width / window.innerWidth * 100);
            return width + (percent ? '%' : 'px');
        }


        function setBounds(left, top, width) {
            //console.log(`setBounds(${left}, ${top}, ${width})`);
            if (typeof left === 'undefined') {
                rect = rect || el.getBoundingClientRect();
                left = rect.left;
                top = rect.top;
                width = rect.width;
            }
            el.style.left = left + 'px';
            el.style.top = top + 'px';
            el.style.width = convertWidthUnits(width);
        }


        function getBounds() {
            //console.log('getBounds()');
            return [0, 0, window.innerWidth];
        }


        function togglePercent(state = !percent) {
            //console.log(`togglePercent(${state})`);
            percent = state;
            setBounds();
        }


        function calc(ev) {
            //console.log(`calc(${ev})`);
            rect = el.getBoundingClientRect();
            left = ev.clientX - rect.left;
            top = ev.clientY - rect.top;
            right = rect.right;
            edge = (left <= 32 && left > -5) ? 'left' :
                (left >= rect.width - 32 && left < rect.width + 5) ? 'right' : null;
        }


        function onUp(ev) {
            //console.log(`onUp(${ev})`);
            calc(ev);
            action = null;
            clicked = null;
            edge = null;
            document.body.style.cursor = '';
        }


        function onTouchEnd(ev) {
            //console.log(`onTouchEnd(${ev})`);
            ev.touches.length === 0 && onUp(ev.changedTouches[0]);
        }


        function onMove(ev) {
            //console.log(`onMove(${ev})`);
            calc(ev);
            thisEvent = ev;
            redraw = true;
        }


        function onTouchMove(ev) {
            console.log(`onTouchMove(${ev})`);
            onMove(ev.touches[0]);
            if (clicked && action) {
                ev.preventDefault();
                ev.stopPropagation();
            }
        }


        function onDown(ev) {
            //console.log(`onDown(${ev})`);
            //action && ev.preventDefault();
            calc(ev);

            clicked = {
                left, top, edge,
                clientX: ev.clientX,
                clientY: ev.clientY,
                width: rect.width,
                height: rect.height
            };
        }


        function onTouchDown(ev) {
            //console.log(`onTouchDown(${ev})`);
            onDown(ev.touches[0]);
            //ev.preventDefault();
        }


        function animate() {
            requestAnimationFrame(animate);
            if (!redraw) { return; }
            redraw = false;

            if (clicked && action === 'resize') {
                if (clicked.edge === 'right') {
                    let elemWidth = Math.max(left, minWidth);
                    el.style.width = elemWidth + 'px';
                    sot.setRulerLabel(elemWidth);
                }
                if (clicked.edge === 'left') {
                    //let elemWidth = clicked.clientX - thisEvent.clientX + clicked.width;
                    let elemWidth = right - thisEvent.clientX;
                    if (elemWidth > minWidth) {
                        el.style.width = elemWidth + 'px';
                        el.style.left = thisEvent.clientX + 'px';
                        sot.setRulerLabel(elemWidth);
                    }
                }
            } else if (clicked && action === 'move') {
                el.style.top = (thisEvent.clientY - clicked.top) + 'px';
                el.style.left = (thisEvent.clientX - clicked.left) + 'px';
            } else {
                clicked = null;
            }
        }


        for (let handle of handles) {
            handle.addEventListener('mousedown', handleDown);
            handle.addEventListener('touchstart', handleDown);
        }

        document.addEventListener('mousedown', onDown);
        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onUp);
        document.addEventListener('touchstart', onTouchDown);
        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', onTouchEnd);

        animate();

        // Make togglePercent method public
        return { togglePercent };
    };

    return { create };
}();
