/* globals mar, olap, elem, opt, sot */
'use strict';

elem.scaleSlider.value = 5;
sot.setScale();
sot.setOptionLabels();
sot.setMaskWidths();

elem.options.classList.remove('hide');
opt.state = opt.merge();
opt.setAll();

if (olap) {
    sot.isOverlapping = olap();
    sot.setEventVisibility();
}

mar && mar.create(elem.ruler);

elem.notice.addEventListener('click', () => {
    console.info('Event: click on notice');
    elem.notice.classList.remove('show-notice');
});

elem.menuButton.addEventListener('click', sot.toggleMenu);

elem.scaleSlider.addEventListener('change', () => {
    console.info('Event: scale change');
    opt.state.effects || sot.setScale();
});

elem.scaleSlider.addEventListener('input', () => {
    //console.info('Event: scale input');
    sot.setOptionLabels();
    opt.state.effects && sot.setScale();
});

elem.scaleMin.addEventListener('click', () => {
    console.info('Event: click on scale-min');
    elem.scaleSlider.value = elem.scaleSlider.min;
    sot.setOptionLabels();
    sot.setScale();
});

elem.scaleMax.addEventListener('click', () => {
    console.info('Event: click on scale-max');
    elem.scaleSlider.value = elem.scaleSlider.max;
    sot.setOptionLabels();
    sot.setScale();
});

Array.from(elem.options.querySelectorAll('[type="checkbox"]')).forEach(input => {
    input.addEventListener('change', function () {
        console.log(`${this.id} changed`);
        sot.setCheckboxOption(this.id.substring(7, this.id.length), this);
    });
});

Array.from(elem.options.querySelectorAll('[type="radio"]')).forEach(input => {
    input.addEventListener('change', function () {
        console.log(`${this.name} changed`);
        sot.setRadioOption(this.name, this.value, this);
        // TODO: Ensure start is always before end
    });
});

window.onresize = () => {
    console.info('Event: window resize');
    if (elem.options.classList.contains('show-menu')) {
        elem.options.style.height = '';
        setTimeout(() => {
            sot.optHeightMax = elem.options.getBoundingClientRect().height;
            elem.options.style.height = sot.optHeightMax + 'px';
        }, 200);
    } else {
        sot.menuTimeout = null;
        sot.optHeightMax = null;
    }
    sot.setScale();
    //sot.setRangeDetail();
    sot.setMaskWidths();
    //sot.setEventVisibility();
};

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('sw.js').then(reg => {
        console.info('Service worker registered with scope:', reg.scope);
    }).catch(error => {
        console.error('Service worker not registered:', error);
    });

    navigator.serviceWorker.addEventListener('controllerchange', event => {
        console.info('Event: service worker controller change');
        navigator.serviceWorker.controller.addEventListener('statechange', function () {
            console.info('Event: service worker state changed to ' + this.state);
            if (this.state === 'activated') {
                elem.notice.innerHTML = 'Your browser has made this site available offline.';
                elem.notice.classList.add('show-notice');
                setTimeout(() => {
                    elem.notice.classList.remove('show-notice');
                }, 6000);
            }
        });
    });
}
