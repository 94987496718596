/**
 * Returns a function that checks if two elements are overlapping on the screen’s x-axis.
 *
 * Init:  const isOverlapping = olap();
 * Use:   isOverlapping(el1, el2);
 */

const olap = function () {
    'use strict';

    function getScreenX(el) {
        var x = el.offsetLeft;
        while (el.offsetParent) {
            x = x + el.offsetParent.offsetLeft;
            el = el.offsetParent || {};
        }
        return x;
    }

    function getSmallerX(el1, el2) {
        return getScreenX(el1) < getScreenX(el2) ? el1 : el2;
    }

    function intersect(el1, el2) {
        const elX1 = getSmallerX(el1, el2),
              elX2 = (el1 != elX1) ? el1 : el2;
        return getScreenX(elX1) + elX1.clientWidth - getScreenX(elX2) > 0;
    }

    return function (el1, el2) {
        return intersect(el1, el2);
    };

};
