/* globals elem, opt */
'use strict';

const sot = {

    createUrlQuery(options = opt.state) {
        console.log('sot.createUrlQuery()');
        let query = '';
        for (let option of Object.keys(options)) {
            options[option] && (query += `&${option}=${options[option]}`);
        }
        query && (query = '?' + query.substring(1, query.length));
        return query;
    },


    setUrl(query = opt.state.url ? sot.createUrlQuery() : '') {
        console.log(`sot.setUrl(${query})`);
        const url = window.location.href;
        window.history.replaceState({}, '', url + query);
    },


    setCheckboxOption(option, optionElem = document.getElementById('option-' + option), isChecked = optionElem.checked) {
        console.log(`sot.setCheckboxOption(${option}, ${optionElem}, ${isChecked})`);
        let affectedElem = optionElem.name ? document.getElementById(optionElem.name) : document.body;
        affectedElem.classList[isChecked ? 'add' : 'remove'](`show-${option}`);
        optionElem.checked = isChecked;
        opt.store(option, isChecked);
        sot.setUrl();
    },


    setRadioOption(option, value, optionElem = document.getElementById(`option-${option}-${value}`)) {
        console.log(`sot.setRadioOption(${option}, ${value}, ${optionElem})`);
        if (optionElem.name === 'start') {
            elem.timelines.classList.remove('start-bang', 'start-earth', 'start-humans');
        } else if (optionElem.name === 'end') {
            elem.timelines.classList.remove('end-earth', 'end-humans', 'end-present');
        }
        elem.timelines.classList.add(`${option}-${value}`);
        optionElem.checked = true;
        opt.store(option, value);
        sot.setUrl();
    },


    isOverlapping(elem1, elem2) {
        //console.log(`sot.isOverlapping(${elem1}, ${elem2})`);
        return false;
    },

    isScrollX(x) {
        //console.log(`sot.isScrollX(${x})`);
        return (typeof x === 'number') && (x >= 0) && (x <= sot.getScrollMax());
    },

    isPercent(percent) {
        //console.log(`sot.isPercent(${percent})`);
        return (typeof percent === 'number') && (percent >= 0) && (percent <= 100);
    },

    getScaleZoom() {
        console.log(`sot.getScaleZoom()`);
        return parseInt(elem.scaleSlider.value) || 1;
    },

    getScale(zoom = sot.getScaleZoom()) {
        console.log(`sot.getScale()`);
        return zoom * window.innerWidth * 0.9;
    },

    getScaleWidth(scale = sot.getScale()) {
        console.log(`sot.getScaleWidth()`);
        return Math.round((scale / 69) / 10) * 10;
    },

    getRulerWidth(isFull) {
        console.log(`sot.getRulerWidth(${isFull})`);
        return isFull ? window.innerWidth * 0.9 : elem.ruler.getBoundingClientRect().width;
    },

    getScrollMax() {
        console.log(`sot.getScrollMax()`);
        return window.scrollMaxX || document.body.clientWidth || document.body.scrollWidth;
    },

    getScrollX(percent = sot.getScrollPercent()) {
        console.log(`sot.getScrollX(${percent})`);
        return sot.isPercent(percent) ? sot.getScrollMax() / 100 * percent : null;
    },

    getScrollPercent(x = window.scrollX || window.pageXOffset) {
        console.log(`sot.getScrollPercent(${x})`);
        return sot.isScrollX(x) ? x / sot.getScrollMax() * 100 : null;
    },

    setScrollX(x) {
        //console.log(`sot.setScrollX(${x})`);
        sot.isScrollX(x) && window.scrollTo(x, 0);
    },

    setScrollPercent(percent) {
        console.log(`sot.setScrollPercent(${percent})`);
        sot.isPercent(percent) && sot.setScrollX(sot.getScrollX(percent));
    },

    setOptionLabels() {
        console.log('setOptionLabels()');
        elem.scaleMin.className = (elem.scaleSlider.value === elem.scaleSlider.min) ? 'active' : '';
        elem.scaleMax.className = (elem.scaleSlider.value === elem.scaleSlider.max) ? 'active' : '';
    },


    setRangeDetail(scale, scaleWidth = sot.getScaleWidth(scale)) {
        console.log(`sot.setRangeDetail(${scale}, ${scaleWidth})`);
        /*jshint ignore:start */
        switch (scaleWidth) {
            case 0:
                console.error('scaleWidth is 0');
            case 10:
            case 20:
                elem.darkAges.classList.add('truncate');
            case 30:
                elem.truncate('phanerozoic', 'paleoproterozoic');
            case 40:
                elem.eoarchaen.classList.add('truncate');
            case 50:
                elem.truncate('paleoarchaen', 'mesoarchaen', 'mesoproterozoic', 'paleozoic');
            case 60:
                elem.truncate('reionization', 'hadean', 'neoarchaen', 'neoproterozoic');
            case 70:
                elem.mesozoic.classList.add('truncate');
            case 100:
                elem.cenozoic.classList.add('truncate');
            case 110:
                //document.body.classList.add('thin');
                Array.from(elem.periodsRange).forEach(range => {
                    range.classList.add('hide');
                });
                elem.height(10, 'darkAges', 'reionization', 'stellar');
            case 200:
                //elem.hide('earth', 'dinosaurs', 'mammals', 'primates', 'greatApes', 'homo');
                break;
            default:
                //document.body.classList.remove('thin');
                Array.from(elem.periodsRange).forEach(range => {
                    range.classList.remove('hide');
                });
                elem.height(15, 'darkAges', 'reionization', 'stellar');
                elem.untruncate('phanerozoic', 'paleoproterozoic', 'eoarchaen', 'paleoarchaen',
                    'mesoarchaen', 'mesoproterozoic', 'paleozoic', 'darkAges', 'reionization',
                    'hadean', 'neoarchaen', 'neoproterozoic', 'mesozoic', 'cenozoic');
                //elem.unhide('earth', 'dinosaurs', 'mammals', 'primates', 'greatApes', 'homo');
        }
        /*jshint ignore:end */
    },


    setEventVisibility() {
        console.log('sot.setEventVisibility()');
        for (let i = 1; i < elem.events.length; i++) {
            elem.events[i].classList.remove('hide');
            let h = i - 1;
            while (h >= 0) {
                if (elem.events[h].classList.contains('hide')) {
                    --h;
                } else {
                    break;
                }
            }
            if (sot.isOverlapping(elem.events[h], elem.events[i])) {
                elem.events[i].classList.add('hide');
            }
        }
    },


    setMaskWidths() {
        console.log('sot.setMaskWidths()');
        Array.from(elem.events).forEach(event => {
            const mask = event.querySelector('.mask');
            if (mask) {
                const image = event.querySelector('.event-image');
                mask.style.width = window.getComputedStyle(image).width;
            }
        });
    },


    setRulerLabel(rulerWidth = sot.getRulerWidth(), scale = sot.getScale(), scaleZoom = sot.getScaleZoom()) {
        console.log(`sot.setRulerLabel(${scale}, ${scaleZoom}, ${rulerWidth})`);
        let units, years = (13800 / scaleZoom) * (rulerWidth / (scale / scaleZoom));
        if (years > 999) {
            years = Math.round(years / 100) / 10;
            units = 'billion';
        } else if (years >= 20) {
            years = Math.round(years);
            units = 'million';
        } else {
            years = Math.round(years * 10) / 10;
            units = 'million';
        }
        elem.rulerLabel.innerHTML = `${years} ${units} years`;
    },


    setScale(scale = sot.getScale(), scrollPercent = sot.getScrollPercent()) {
        console.log(`sot.setScale(${scale}, ${scrollPercent})`);
        elem.timelines.style.backgroundSize = scale / 13.8 + 'px 100%';
        elem.timelines.style.width = scale + 'px';
        sot.setRulerLabel(undefined, scale);
        sot.setRangeDetail(scale);
        sot.setEventVisibility();
        sot.setScrollPercent(scrollPercent);
    },


    toggleMenu() {
        console.log('sot.toggleMenu()');
        if (elem.options.classList.contains('show-menu')) {
            sot.optHeightMax || sot.menuTimeout && clearTimeout(sot.menuTimeout);
            elem.options.style.height = '';
        } else {
            if (sot.optHeightMax) {
                elem.options.style.height = sot.optHeightMax + 'px';
            } else {
                sot.menuTimeout = setTimeout(() => {
                    sot.optHeightMax = elem.options.getBoundingClientRect().height;
                    elem.options.style.height = sot.optHeightMax + 'px';
                }, opt.state.effects ? 2000 : 200);
            }
        }
        elem.shadow.classList.toggle('dark');
        elem.options.classList.toggle('show-menu');
    }
};
