/* globals sot */
'use strict';

const opt = {

    state: {
        ranges:   true,
        events:   true,
        start:    'bang',
        end:      'present',
        desc:     true,
        ruler:    true,
        lines:    true,
        effects:  true,
        url:      false
    },


    getFromUrl() {
        console.log('opt.getFromUrl()');
        var query = window.location.search.substring(1),
            pairs = query.split('&'),
            options = {};
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('='),
                option = pair[0],
                value = (pair[1] === 'true') ? true : (pair[1] === 'false') ? false : pair[1];
            console.log('pair[1]:' + pair[1]);
            opt.state.hasOwnProperty(option) && (options[option] = value);
        }
        return options;
    },


    getFromStorage() {
        console.log('opt.getFromStorage()');
        return localStorage ? JSON.parse(localStorage.getItem('options')) || opt.state : opt.state;
    },


    store(option, value) {
        console.log(`opt.store(${option}, ${value})`);
        opt.state[option] = value;
        localStorage && localStorage.setItem('options', JSON.stringify(opt.state));
    },


    // Does not make a deep copy of options1
    merge(options1 = opt.getFromStorage(), options2 = opt.getFromUrl()) {
        console.log('opt.merge()');
        for (let option in options2) {
            options1[option] = options2[option];
        }
        return options1;
    },


    setAll(options = opt.state) {
        console.log('opt.setAll()');
        for (let option of Object.keys(options)) {
            let value = options[option];
            if (typeof value === 'boolean') {
                sot.setCheckboxOption(option, undefined, value);
            } else if (typeof value === 'string') {
                sot.setRadioOption(option, value);
            }
        }
    }

};
